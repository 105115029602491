import { UrgentListType } from "./urgent";

export const UrgentListTypeFromJson = (json: Array<any>): UrgentListType => {
  return json.map((row) => {
    return {
      id: row.id,
      category: row.category,
      thumbnail: row.member.thumbnail,
      nickname: row.member.nickname,
      member_id: row.member.id,
      tstamp: row.tstamp,
      commentCount: row.comment_count,
      content: row.detail,
    };
  });
};
