
import { defineComponent } from "vue";
import { UrgentListType } from "./urgent";
import UrgentListItem from "./ListItem.vue";

export default defineComponent({
  name: "UrgentList",
  components: {
    UrgentListItem,
  },
  props: {
    urgents: {
      type: Object as () => UrgentListType,
      required: true,
    },
  },
});
